ion-radio {
  --size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: size(12);

  margin: 0;
  width: var(--size);
  height: var(--size);
  border: 2px solid rgba(var(--ion-color-secondary-rgb), 0.6);
  border-radius: 50%;
}

ion-radio-group {
  .item.sc-ion-label-ios-h,
  .item .sc-ion-label-ios-h {
    font-size: size(14);
    font-weight: var(--f-regular);
    color: var(--ion-color-secondary);
  }
}
