/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* @ng-select default theme */
@import "~@ng-select/ng-select/themes/default.theme.css";

/* @ctrl/ngx-emoji-mart */
@import "~@ctrl/ngx-emoji-mart/picker";

/* Custom styles */
@import "./theme/mixins.scss";
@import "./theme/ion-modal.scss";
@import "./theme/ion-content.scss";
@import "./theme/ion-header.scss";
@import "./theme/ion-button.scss";
@import "./theme/ion-list.scss";
@import "./theme/ion-menu.scss";
@import "./theme/ion-tabs.scss";
@import "./theme/ion-item.scss";
@import "./theme/ion-label.scss";
@import "./theme/ion-input.scss";
@import "./theme/ion-back-button.scss";
@import "./theme/ion-menu-button.scss";
@import "./theme/ion-radio.scss";
@import "./theme/ion-checkbox.scss";
@import "./theme/ion-select.scss";
@import "./theme/ion-toast.scss";
@import "./theme/ion-popover.scss";
