ion-item {
  position: relative;

  width: 100%;

  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --min-height: auto;
  --inner-border-width: 0 0 2px 0;
  --border-color: var(--ion-color-light);
  --detail-icon-color: var(--ion-color-secondary);
  --detail-icon-font-size: 20px;
  --detail-icon-opacity: 1;
  --background: transparent;

  &.select-interface-option {
    --inner-padding-top: calc(var(--l-space) / 2);
    --inner-padding-bottom: calc(var(--l-space) / 2);
    --inner-padding-start: calc(var(--l-space) / 2);
    --inner-padding-end: calc(var(--l-space) / 2);

    &:last-child {
      --border-style: none;
    }
  }

  @media (min-width: 768px) {
    --border-color: var(--ion-color-secondary);
  }
}
