@import "./breakpoints.scss";

ion-content {
  --padding-top: 0;
  --padding-bottom: var(--l-space);
  --padding-start: var(--l-space);
  --padding-end: var(--l-space);
  --background: var(--ion-color-background);

  &.--mobile-white,
  &.--white {
    --padding-top: var(--l-space);
    --background: var(--ion-color-light);
  }

  &.--no-padding {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
  }

  .wrapper {
    position: relative;

    padding-top: calc(var(--l-space) * 2);

    &__title {
      font-size: size(20);
      font-weight: var(--f-semi-bold);
      color: var(--ion-color-secondary);
    }
  }

  .white-wrapper {
    margin: 0 auto;
    max-width: 1000px;
  }

  @media (min-width: 768px) {
    --padding-top: var(--l-space);
    --padding-bottom: var(--l-space);
    --padding-start: var(--l-space);
    --padding-end: var(--l-space);

    &.--mobile-white {
      --padding-top: 0;

      --background: var(--ion-color-background);
    }

    .white-wrapper {
      padding: var(--l-space);
      border-radius: 10px;
    }
  }

  @media (min-width: $ipad-width-min) {
    .wrapper {
      padding: calc(var(--l-space) * 2) var(--l-space);

      border-radius: 10px;

      background-color: var(--ion-color-light);
    }

    .white-wrapper {
      background-color: var(--ion-color-light);
    }
  }
}
