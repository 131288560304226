@import "./breakpoints";

.header {
  ion-toolbar {
    --padding-top: var(--l-space);
    --padding-start: var(--l-space);
    --padding-bottom: var(--l-space);
    --padding-end: var(--l-space);

    --border-style: none;
    --background: var(--ion-color-background);

    ion-title {
      padding-left: var(--l-space);
      width: auto;
      max-width: 100%;

      font-size: size(24);
      font-weight: var(--f-semi-bold);

      --color: var(--ion-color-secondary);

      &.--logo {
        span {
          display: none;

          font-size: size(24);
          font-weight: var(--f-semi-bold);
          color: var(--ion-primary-color);
        }
      }

      &.--no-padding {
        padding-left: var(--l-space);
      }
    }

    ion-buttons {
      gap: calc(var(--l-space) / 2);

      ion-back-button,
      ion-menu-button {
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;

        color: var(--ion-color-secondary);
      }

      ion-button {
        &.icon {
          margin: 0;

          font-size: size(20);

          --padding-top: 0;
          --padding-end: 0;
          --padding-bottom: 0;
          --padding-start: 0;
          --color: var(--ion-color-secondary);
        }

        &.text {
          font-size: size(16);
          font-weight: var(--f-regular);

          &.--success {
            --color: var(--ion-color-success);
          }

          &.--edit {
            --color: var(--ion-color-tertiary);
          }
        }
      }

      ion-menu-button {
        font-size: size(30);

        --color: var(--ion-color-secondary);
        --padding-start: 0;
        --padding-end: 0;
      }
    }
  }

  &__logo {
    display: inline-block;

    vertical-align: middle;

    &__image {
      display: block;
    }
  }

  &__burger {
    --color: var(--ion-color-secondary);
  }

  &__avatar {
    display: none; // TODO: To be remove when we have the real avatar
    width: size(35);
    height: size(35);
  }

  &.--underline {
    ion-toolbar {
      --border-style: solid;
      --border-color: var(--ion-color-background-shade);
    }
  }

  &.--transparent {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    ion-toolbar {
      --background: transparent;

      ion-buttons {
        ion-back-button,
        .header__burger {
          color: var(--ion-color-light);
        }
      }

      ion-title {
        display: none;
      }
    }
  }

  &.--w-back-button {
    ion-toolbar {
      ion-title {
        padding-left: calc(var(--l-space) * 3);
      }
    }
  }

  &.--w-white-bg {
    ion-toolbar {
      --background: var(--ion-color-light);
    }
  }

  @media (min-width: $ipad-width-min) {
    ion-toolbar {
      --min-height: 100px;

      .toolbar-container {
        background-color: red;
      }

      ion-title {
        align-items: flex-end;

        &.--logo {
          span {
            display: block;
            margin-top: var(--l-space);
          }
        }
      }

      ion-buttons {
        ion-back-button {
          display: none;
        }
      }
    }

    &__logo {
      display: none;
    }

    &__burger {
      display: none;
    }

    &.--underline {
      ion-toolbar {
        --border-style: none;

        ion-title {
          display: none;
        }
      }
    }

    &.--transparent {
      position: static;

      background-color: var(--ion-color-background);

      ion-toolbar {
        ion-title {
          display: flex;
        }
      }
    }

    &.--w-back-button {
      ion-toolbar {
        ion-title {
          padding-left: var(--l-space);
        }
      }
    }
  }
}
