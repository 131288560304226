ion-select {
  width: 100%;
  height: 43px;
}

.--m-width {
  .alert-wrapper {
    max-width: unset;
    width: 300px;
  }
}

.--l-width {
  .alert-wrapper {
    max-width: unset;
    width: 400px;
  }
}
