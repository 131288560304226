// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Open Sans - Google Font **/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #c77373;
  --ion-color-primary-rgb: 199, 115, 115;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #af6565;
  --ion-color-primary-tint: #cd8181;

  /** secondary **/
  --ion-color-secondary: #452828;
  --ion-color-secondary-rgb: 69, 40, 40;
  --ion-color-secondary-contrast: #ffffffef;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3d2323;
  --ion-color-secondary-tint: #583e3e;

  /** tertiary **/
  --ion-color-tertiary: #4f8f8f;
  --ion-color-tertiary-rgb: 79, 143, 143;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #467e7e;
  --ion-color-tertiary-tint: #619a9a;

  /** success **/
  --ion-color-success: #779a4e;
  --ion-color-success-rgb: 119, 154, 78;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #698845;
  --ion-color-success-tint: #85a460;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #8f7e7e;
  --ion-color-dark-tint: #1a1a1a;

  /** medium **/
  --ion-color-medium: #66625f;
  --ion-color-medium-rgb: 102, 98, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5a5654;
  --ion-color-medium-tint: #75726f;

  /** medium light **/
  --ion-color-medium-light: #fafafa;
  --ion-color-medium-light-rgb: 250, 250, 250;
  --ion-color-medium-light-contrast: #000000;
  --ion-color-medium-light-contrast-rgb: 0, 0, 0;
  --ion-color-medium-light-shade: #dcdcdc;
  --ion-color-medium-light-tint: #fbfbfb;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #ececec;
  --ion-color-light-tint: #ffffff;

  /** Background **/
  --ion-color-background: #fff5ee;
  --ion-color-background-rgb: 255, 245, 238;
  --ion-color-background-contrast: #000000;
  --ion-color-background-contrast-rgb: 0, 0, 0;
  --ion-color-background-shade: #e0d8d1;
  --ion-color-background-tint: #fff6f0;

  /** Inner Background **/
  --ion-color-inner-background: #fff3e3;
  --ion-color-inner-background-rgb: 255, 243, 227;
  --ion-color-inner-background-contrast: #000000;
  --ion-color-inner-background-contrast-rgb: 0, 0, 0;
  --ion-color-inner-background-shade: #e0d6c8;
  --ion-color-inner-background-tint: #fff4e6;

  /** Border **/
  --ion-color-border: #f0dacf;
  --ion-color-border-rgb: 240, 218, 207;
  --ion-color-border-contrast: #000000;
  --ion-color-border-contrast-rgb: 0, 0, 0;
  --ion-color-border-shade: #d3c0b6;
  --ion-color-border-tint: #f2ded4;

  /** Challenge **/
  --ion-color-challenge: #f4e1ca;
  --ion-color-challenge-rgb: 244, 225, 202;
  --ion-color-challenge-contrast: #000000;
  --ion-color-challenge-contrast-rgb: 0, 0, 0;
  --ion-color-challenge-shade: #d7c6b2;
  --ion-color-challenge-tint: #f5e4cf;

  /** Info **/
  --ion-color-info: #e8a351;
  --ion-color-info-rgb: 232, 163, 81;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #cc8f47;
  --ion-color-info-tint: #eaac62;

  /** Bullet **/
  --ion-color-bullet: #fcf2ea;
  --ion-color-bullet-rgb: 252, 242, 234;
  --ion-color-bullet-contrast: #000000;
  --ion-color-bullet-contrast-rgb: 0, 0, 0;
  --ion-color-bullet-shade: #ded5ce;
  --ion-color-bullet-tint: #fffaf6;

  /** Query **/
  --ion-color-query: #fdf7e6;
  --ion-color-query-rgb: 44, 9, 99;
  --ion-color-query-contrast: #565552;
  --ion-color-query-contrast-rgb: 45, 5, 34;
  --ion-color-query-shade: #fdf7e6;
  --ion-color-query-tint: #fdf7e6;

  /** Font family **/
  --ion-font-family: "Open Sans", sans-serif;

  /** Custom **/

  // layout
  --l-space: 20px;

  // font
  --f-light: 300;
  --f-regular: 400;
  --f-medium: 500;
  --f-semi-bold: 600;
  --f-bold: 700;
  --f-extra-bold: 800;
  --f-black: 900;
}
