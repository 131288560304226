.button {
  margin: 0;
  height: 50px;

  font-size: size(18);
  font-weight: var(--f-semi-bold);

  --padding-start: calc(var(--l-space) * 3);
  --padding-end: calc(var(--l-space) * 3);
  --border-radius: 50px;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --color: var(--ion-color-light);
  --background: var(--ion-color-primary);
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --transition: 0.25s;

  &.--primary {
    &:hover {
      --color: var(--ion-color-light);

      --background: var(--ion-color-primary-shade);
    }
  }

  &.--success {
    --background: var(--ion-color-success);

    &:hover {
      --color: var(--ion-color-light);
      --background: var(--ion-color-success-shade);
    }
  }

  &.--primary-outline {
    --color: var(--ion-color-primary);
    --border-width: 2px;
    --border-style: solid;
    --border-color: var(--ion-color-primary);
    --background: transparent;

    &:hover {
      --color: var(--ion-color-light);
      --background: var(--ion-color-primary);
    }
  }

  &.--secondary {
    height: 50px;

    font-size: size(16);
    font-weight: var(--f-semi-bold);

    --padding-start: calc(var(--l-space));
    --padding-end: calc(var(--l-space));
    --color: var(--ion-color-warning-shade);
    --border-width: 2px;
    --border-style: solid;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
  }

  &.--tertiary {
    --background: var(--ion-color-secondary);
  }

  &.--dark-outline {
    --color: var(--ion-color-dark);
    --border-width: 2px;
    --border-style: solid;
    --border-color: var(--ion-color-dark);
    --background: transparent;

    &:hover {
      --color: var(--ion-color-light);
      --background: var(--ion-color-dark);
    }
  }

  &.--text {
    height: 20px;

    font-size: size(16);
    font-weight: var(--f-semi-bold);
    text-decoration: underline;

    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --border-radius: 0;
    --background: transparent;

    &.--dark {
      --color: var(--ion-color-secondary);
    }

    &.--white-text {
      --color: var(--ion-color-light);
      --color-hover: var(--ion-color-light-contrast);
    }

    &:hover {
      --background: transparent;
    }
  }

  &.--text-logo {
    text-decoration: none;

    ion-icon {
      margin-right: calc(var(--l-space) / 2);
    }
  }

  &.--icon {
    --color: var(--ion-color-secondary);
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --border-radius: 0;
    --background: transparent;

    &.--dark {
      --color: var(--ion-color-secondary);
    }

    &:hover {
      --color: var(--ion-color-primary);
      --background: transparent;
    }
  }

  &.--w-icon {
    --padding-start: calc(var(--l-space) * 2);
    --padding-end: calc(var(--l-space) * 2);

    ion-icon {
      margin-left: calc(var(--l-space) / 2);
    }

    &.--icon-left {
      ion-icon {
        margin-left: 0;
        margin-right: calc(var(--l-space) / 2);
      }
    }
  }

  &.--outline {
    --border-width: 2px;
    --border-style: solid;
    --border-color: var(--ion-color-primary);
    --color: var(--ion-color-primary);
    --background: transparent;
  }

  &.--white {
    --color: var(--ion-color-secondary);
    --background: var(--ion-color-light);
  }

  &.--big {
    height: 50px;
    font-size: size(18);
    --border-radius: 50px;
  }

  &.--small {
    height: 35px;

    font-size: size(14);

    --padding-start: var(--l-space);
    --padding-end: var(--l-space);
  }

  &.--greyed-out {
    --opacity: 0.5;
  }

  &:hover {
    --color: var(--ion-color-secondary);
    --background: var(--ion-color-light);
  }
}

@media (min-width: 768px) {
  .button {
    &.--secondary {
      height: 32px;
    }

    &.--text {
      --color: var(--ion-color-secondary);

      &:hover {
        --color: var(--ion-color-primary);
      }
    }
  }
}
