.item:not(.no-item) {
  max-width: 400px;

  ion-input {
    line-height: 2;

    .native-input.sc-ion-input-ios {
      overflow: hidden;

      flex: none;

      width: 90%;

      text-overflow: ellipsis;
    }
    &.input {
      color: var(--ion-color-light);
      font-weight: var(--f-semi-bold);

      --padding-top: 0;
      --padding-bottom: calc(var(--l-space) / 2);
      --padding-start: calc(var(--l-space) / 2);
      --padding-end: calc(var(--l-space) / 2);
      --placeholder-color: var(--ion-color-light);
      --placeholder-opacity: 1;
      --background: transparent;

      input {
        &::-webkit-input-placeholder {
          font-size: size(16);
        }
      }
    }
  }

  .input-button {
    position: absolute;
    right: calc(var(--l-space) / 2);
    top: var(--l-space);
    z-index: 2;

    --padding-start: 0;
    --padding-end: 0;

    ion-icon {
      --size: 20px;
      width: var(--size);
      height: var(--size);
      color: var(--ion-color-light);
      margin: auto;
    }
  }

  @media (min-width: 768px) {
    ion-input {
      &.input {
        color: var(--ion-color-secondary);

        --placeholder-color: var(--ion-color-secondary);
      }
    }

    .input-button {
      ion-icon {
        color: var(--ion-color-secondary);
      }
    }
  }
}
