/* stylelint-disable declaration-no-important */
@import "./breakpoints.scss";

.swiper-survey-modal {
  --backdrop-opacity: 0.9 !important;

  &::part(backdrop) {
    background: white;
  }

  @media (min-width: $mobile-width-min) {
    --min-width: 1000px;
    --height: 562px;
  }
}

ion-modal.modal-card {
  &.event-detail-modal,
  &.event-reschedule-modal,
  &.chance-node-logs-modal {
    @media (min-width: $mobile-width-min) {
      --max-width: unset;
    }
  }
}

ion-modal {
  &.--force-action-button {
    --min-width: 50vw;
    --min-height: 90vh;
    --backdrop-opacity: 0.9;

    &::part(backdrop) {
      background: white;
    }
  }

  &.--emoji-picker {
    --width: 338px;
    --height: 360px;
    --border-radius: 5px;

    &::part(backdrop) {
      background: white;
    }
  }

  &.challenge-locked-modal {
    &::part(backdrop) {
      background: white;
    }
  }
}
