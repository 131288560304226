@import "./breakpoints";

.breadcrumb {
  display: none;
  align-items: center;

  background-color: transparent;

  &__item {
    width: auto;

    font-size: 14px;
    font-weight: var(--f-regular);
    color: var(--ion-color-secondary);

    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --min-height: 0;
    --border-style: none;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;

    &__dash {
      margin: 0 calc(var(--l-space) / 4);
    }

    &:last-child {
      .breadcrumb__item__dash {
        display: none;
      }
    }
  }

  @media (min-width: $ipad-width-min) {
    display: flex;

    padding-bottom: var(--l-space);
  }
}

ion-list-header {
  padding: 0;
}
