@import "./breakpoints";

.menu {
  border: none;

  --background: var(--ion-color-medium);

  ion-content {
    --padding-top: calc(var(--l-space) * 2);
    --padding-bottom: calc(var(--l-space) * 3);
    --padding-start: 0;
    --padding-end: 0;
    --background: transparent;
  }

  &__logo {
    display: none;
  }

  &__close {
    --width: 34px;

    margin-left: calc(100% - (var(--width) + var(--l-space)));
    width: var(--width);

    font-size: 34px;
    color: var(--ion-color-light);
  }

  &__sublist {
    overflow: hidden;

    max-height: 0;

    background-color: transparent;

    transition: max-height 0.25s ease;

    &__item {
      margin: 0;

      font-size: 14px;
      font-weight: var(--f-semi-bold);
      color: rgba(var(--ion-color-light-rgb), 0.8);

      --padding-top: calc(var(--l-space) / 2);
      --padding-bottom: calc(var(--l-space) / 2);
      --padding-start: calc(var(--l-space) * 1.5);
      --padding-end: 0;
      --inner-padding-top: 0;
      --inner-padding-bottom: 0;
      --inner-padding-start: 0;
      --inner-padding-end: 0;
      --min-height: 0;
      --border-style: none;
      --color-activated: rgba(var(--ion-color-light-rgb), 0.8);
      --color-focused: rgba(var(--ion-color-light-rgb), 0.8);
      --color-hover: rgba(var(--ion-color-light-rgb), 0.8);
      --background: transparent;
      --background-activated: transparent;
      --background-focused: transparent;
      --background-hover: transparent;

      cursor: pointer;

      &:first-child {
        margin-top: calc(var(--l-space) / 2);
      }

      &.--active {
        --background: var(--ion-color-primary);
      }
    }
  }

  &__list {
    background: transparent;

    &__item {
      margin-top: var(--l-space);

      font-size: size(16);
      color: var(--ion-color-light);
      font-weight: var(--f-semi-bold);

      --padding-top: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      --padding-end: 0;
      --inner-padding-top: 0;
      --inner-padding-bottom: 0;
      --inner-padding-start: 0;
      --inner-padding-end: 0;
      --min-height: 45px;
      --border-style: none;
      --color-activated: var(--ion-color-light);
      --color-focused: var(--ion-color-light);
      --color-hover: var(--ion-color-light);
      --background: transparent;
      --background-activated: transparent;
      --background-focused: transparent;
      --background-hover: transparent;

      img {
        margin-right: calc(var(--l-space) / 2);
      }

      span {
        flex: 0 0 60%;

        white-space: nowrap;
      }

      ion-icon {
        margin-left: var(--l-space);

        font-size: size(16);
      }

      &:first-child {
        margin-top: 0;
      }

      &__wrapper {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;
      }
      &__content {
        display: flex;
        align-items: center;

        padding-left: var(--l-space);
        width: 100%;
        height: 45px;

        cursor: pointer;

        img {
          width: 20px;
        }
      }

      &.--active {
        --background: var(--ion-color-primary);
      }

      &.--open {
        .menu__sublist {
          max-height: 1000px;

          transition: max-height 1s ease;
        }

        ion-icon {
          transform: rotate(180deg);
          transition: transform 0.25s ease;
        }
      }
    }
  }

  @media (min-width: $ipad-width-min) {
    background-color: var(--ion-color-background);

    &__logo {
      display: flex;

      margin-bottom: calc(var(--l-space) * 2);
      padding-left: var(--l-space);
    }

    &__close {
      display: none;
    }
  }
}

ion-menu::part(container) {
  border-radius: 20px 0 0 20px;
}
ion-menu[side="start"]::part(container) {
  border-radius: 0 20px 20px 0;
}

.split-pane-visible > .split-pane-side {
  min-width: 250px;
  max-width: 250px;
}
