.item {
  ion-label {
    margin: 0;
    padding-left: calc(var(--l-space) / 2);

    &.label {
      font-size: size(12);
      font-weight: var(--f-regular);

      --color: var(--ion-color-light);
    }
  }

  @media (min-width: 768px) {
    ion-label {
      &.label {
        --color: var(--ion-color-secondary);
      }
    }
  }
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
  display: flex;
  align-items: center;
}
